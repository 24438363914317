import React from 'react'
import PropTypes from 'prop-types'
import HyperLink from '../HyperLink'
import classNames from 'classnames'
import { BBBLogo } from './BBBLogo'

const BBBWidget = ({ item, isFooter, statusCode }) => {
  return (
    <HyperLink
      href="https://www.bbb.org/us/ca/san-mateo/profile/debt-relief-services/freedom-debt-relief-llc-1116-65019"
      target="_blank"
      rel={classNames('noreferrer', isFooter ? 'nofollow' : '')}
      childrenClassNames="w-1/2 lg:w-auto flex justify-center"
      clickTracking={false}
    >
      <BBBLogo item={item} statusCode={statusCode} />
    </HyperLink>
  )
}

BBBWidget.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    img: PropTypes.string,
    url: PropTypes.string,
    widget: PropTypes.object,
    alt: PropTypes.string,
  }),
  isFooter: PropTypes.bool,
  statusCode: PropTypes.number,
}

export default BBBWidget
