import Image from 'next/image'
import PropTypes from 'prop-types'
import React, { useCallback, useState, useEffect } from 'react'
import { contentfulImageLoader } from './loader'
import { checkWebpSupport } from 'src/common/utils/checkWebpSupport'
import { isImageFound } from 'src/common/utils/isImageFound'

const Img = (props) => {
  const {
    className,
    imgClassName,
    alt,
    src = '',
    dataTestId,
    layout,
    objectFit,
    height,
    width,
    priority,
    isBackgroundImage,
    styles,
    objectPosition,
    imgWidth,
    customLoader = true,
  } = props
  const isContentful = src.includes('images.ctfassets.net')

  const [supportsWebp, setSupportsWebp] = useState(true)
  const [newSrc, setNewSrc] = useState(src)

  const handleError = useCallback(
    (e) => {
      if (
        isContentful &&
        supportsWebp &&
        e.target.currentSrc.includes('webp')
      ) {
        setSupportsWebp(false)
      }
    },
    [isContentful, supportsWebp, setSupportsWebp]
  )

  useEffect(() => {
    checkWebpSupport(async (isSupported) => {
      if (!isSupported) {
        const result = await isImageFound(src.replace('.webp', '.png'))
        if (result === 200) {
          setNewSrc(src.replace('.webp', '.png'))
        } else {
          setNewSrc(src)
        }
      } else {
        setSupportsWebp(isSupported)
        setNewSrc(src)
      }
    })
  }, [src])

  if (!src) return null

  const imgProps = {
    src: newSrc,
    style: { objectFit, objectPosition },
    className: imgClassName,
    priority,
  }

  if (isContentful && customLoader) {
    Object.assign(imgProps, {
      loader: contentfulImageLoader(supportsWebp),
    })
  }

  const isFill = layout === 'fill'
  let divClassName = className
  if (isFill) {
    divClassName = `${className} overflow-hidden`
    imgProps.sizes = '(max-width: 640px) 100vw, 50vw'
  } else {
    Object.assign(imgProps, { height, width })
  }

  if (layout === 'responsive') {
    imgProps.className = `${imgProps.className} min-h-full max-h-full min-w-full max-w-full aspect-square object-contain`
    Object.assign(imgProps.style, {
      height: 'auto',
      width: imgWidth,
    })
    imgProps.sizes = '100vw'
  }

  if (objectFit === 'cover') {
    imgProps.className = `${imgProps.className} object-cover h-full`
  }

  return (
    <div
      className={`${
        isBackgroundImage ? 'absolute -z-1' : 'relative'
      } ${divClassName}`}
      data-testid={dataTestId}
      style={styles}
    >
      <Image
        alt={alt}
        {...imgProps}
        fill={isFill}
        onError={handleError}
        quality={80}
      />
    </div>
  )
}

Img.defaultProps = {
  className: '',
  imgClassName: '',
  dataTestId: '',
  layout: 'fill',
  objectFit: 'contain',
  height: 1,
  width: 1,
  isBackgroundImage: false,
  priority: false,
  styles: { position: 'relative', height: '30px', width: '30px' },
  imgWidth: '100%',
  customLoader: true,
}

Img.propTypes = {
  objectPosition: PropTypes.string,
  layout: PropTypes.string,
  objectFit: PropTypes.string,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  dataTestId: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  isBackgroundImage: PropTypes.bool,
  priority: PropTypes.bool,
  styles: PropTypes.object,
  imgWidth: PropTypes.string,
  customLoader: PropTypes.bool,
}

export default React.memo(Img)
