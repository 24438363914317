/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import React from 'react'

const ButtonLink = ({
  href,
  className,
  children,
  onClick,
  target,
  ...props
}) => {
  const handleClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(href, e)
    }

    if (target) {
      window.open(href, target)

      return
    }

    window.location.href = href
  }

  return (
    <button
      onClick={(e) => handleClick(e)}
      type="button"
      className={className}
      {...props}
      data-testid="button-link"
    >
      {children}
    </button>
  )
}

ButtonLink.defaultProps = {
  className: '',
  children: null,
  onClick: undefined,
  target: undefined,
}
ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default React.memo(ButtonLink)
