export const isImageFound = async (imagePath) => {
  try {
    const result = await fetch(`${window.location.origin}${imagePath}`, {
      method: 'HEAD',
    })

    return result.status
  } catch (e) {
    return 404
  }
}
