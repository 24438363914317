import Image from 'next/image'
import React, { useCallback } from 'react'
import { isInternalForTealium } from '../../../../common/helpers'
import { useAnalytics } from '../../../../common/analytics'
import PropTypes from 'prop-types'

export const BBBLogo = ({ item, statusCode }) => {
  const { track } = useAnalytics()

  const onClick = useCallback(() => {
    const data = {
      nav_link_section: 'Page',
      click_position: 0,
      click_type: 'Link Click',
      click_url: item.url,
      click_id: 'BBB',
      click_text: `FDR-Web | BBB`,
      track_event: 'link_click',
      event_type: 'track',
      outbound_url: !isInternalForTealium(item.url) ? item.url : '',
    }
    track(data, {}, 'click', statusCode)
  }, [item.url, track, statusCode])

  return (
    <div
      className="flex h-full max-h-8.5 w-1/2 justify-center lg:mr-4 lg:w-auto lg:max-w-30 lg:pb-0 lg:last:mr-0"
      onClick={onClick}
    >
      <Image
        src="/next-assets/footer/bbb.webp"
        width={50}
        height={70}
        alt="A+ rating for Freedom Debt Relief LLC BBB Business Review"
      />
      <div className="flex h-5/6 shrink-0 flex-col items-center pl-1 pt-1.5 text-center text-[#00506E]">
        <span className="max-w-min text-10 font-bold leading-3">
          BBB Rating: A+
        </span>
        <span className="mb-1 mt-0.5 max-w-min text-8 leading-2.5">
          As of 02/16/2024
        </span>
        <span className="text-8">Click for Profile</span>
      </div>
    </div>
  )
}

BBBLogo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    img: PropTypes.string,
    url: PropTypes.string,
    widget: PropTypes.object,
    alt: PropTypes.string,
  }),
  statusCode: PropTypes.number,
}
