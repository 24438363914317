import { useCallback, useEffect, useState } from 'react'

export default function useDeviceDetect(defaultValue = false) {
  const [deviceInfo, setDeviceInfo] = useState({
    isMobile: defaultValue,
    isDesktop: defaultValue,
    isSmall: defaultValue,
    isDeviceDetected: defaultValue,
  })

  const updateDeviceInfo = useCallback(() => {
    setDeviceInfo({
      isMobile: window.matchMedia(`(max-width: 1023px)`).matches,
      isDesktop: window.matchMedia(`(min-width: 1370px)`).matches,
      isSmall: window.matchMedia(`(max-width: 768px)`).matches,
      isDeviceDetected: true,
    })
  }, [])

  useEffect(() => {
    updateDeviceInfo()
    window.addEventListener('resize', updateDeviceInfo)

    return () => {
      window.removeEventListener('resize', updateDeviceInfo)
    }
  }, [updateDeviceInfo])

  return deviceInfo
}
