import React, { useCallback, useMemo, useRef } from 'react'
import Link from 'next/link'
import { useAnalytics } from '../../../common/analytics'
import { externalsRegex } from './expectedDomains'
import {
  buildQueryString,
  findClosestHeadingToHyperlink,
  formatUrl,
} from '../../../common/utils'
import PropTypes from 'prop-types'
import { isInternalForTealium } from '../../../common/helpers'
import { useLeadId } from '../../../common/lead-id'
import { useRouter } from 'next/router'

const Hyperlink = ({
  isLeadCta,
  href,
  title,
  children,
  prefetch,
  childrenClassNames,
  clickTracking,
  clickTrackText,
  statusCode,
  nav_link_section,
  ...rest
}) => {
  let path = formatUrl(href)
  const leadId = useLeadId()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )

  if (href === 'https://apply.freedomdebtrelief.com/home/estimated-debt') {
    path += `?${query}`
  }

  const hyperlinkRef = useRef(null)
  const isExternalLink = useCallback((url) => !externalsRegex.test(url), [])

  const externalLinkProps = useMemo(
    () =>
      isExternalLink(path)
        ? {
            rel: 'nofollow',
            target: '_blank',
          }
        : null,
    [isExternalLink, path]
  )

  const { track } = useAnalytics()

  const onClick = useCallback(
    (e) => {
      if (clickTracking) {
        const closestHeading = findClosestHeadingToHyperlink(hyperlinkRef)
        const innerText = e.target.innerText
        const clickId = innerText.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())

        const data = {
          nav_link_section,
          page_section: title || closestHeading,
          click_type: 'Link Click',
          click_url: href,
          click_id: clickTrackText || clickId,
          click_text: `FDR-Web | ${clickTrackText || innerText}`,
          track_event: 'link_click',
          outbound_url: !isInternalForTealium(href) ? href : '',
        }
        track(data, {}, 'click', statusCode)
      }
    },
    [
      clickTracking,
      nav_link_section,
      title,
      href,
      clickTrackText,
      track,
      statusCode,
    ]
  )

  return (
    <Link
      passHref
      href={path}
      prefetch={prefetch}
      onClick={onClick}
      className={childrenClassNames}
      {...externalLinkProps}
      {...rest}
    >
      {children}
    </Link>
  )
}

export default React.memo(Hyperlink)

Hyperlink.defaultProps = {
  href: undefined,
  prefetch: false,
  clickTracking: true,
  title: '',
  nav_link_section: 'Page',
}

Hyperlink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefetch: PropTypes.bool,
  children: PropTypes.node.isRequired,
  childrenClassNames: PropTypes.string,
  title: PropTypes.string,
  clickTracking: PropTypes.bool,
  statusCode: PropTypes.number,
  isLeadCta: PropTypes.bool,
  clickTrackText: PropTypes.string,
  nav_link_section: PropTypes.string,
}
