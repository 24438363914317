import Img from '../../../../src/components/shared/Img'
import React, { useCallback, useMemo } from 'react'
import { LogosGroup } from './consts'
import useDeviceDetect from '../../../common/hooks/useDeviceDetect'
import PropTypes from 'prop-types'
import TrustPilotWidget from '../TrustPilotWidget'
import { useAnalytics } from '../../../common/analytics'
import { isInternalForTealium } from '../../../common/helpers'
import Hyperlink from '../HyperLink'

export const AwardComponent = ({ item, isFooter, statusCode }) => {
  const { track } = useAnalytics()

  const onClick = useCallback(() => {
    const clickId = item.alt.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())

    const data = {
      nav_link_section: 'Page',
      click_position: 0,
      click_type: 'Link Click',
      click_url: item.url,
      click_id: clickId,
      click_text: `FDR-Web | ${item.alt}`,
      track_event: 'link_click',
      event_type: 'track',
      outbound_url: !isInternalForTealium(item.url) ? item.url : '',
    }
    track(data, {}, 'click', statusCode)
  }, [item.url, item.alt, track, statusCode])

  return (
    <div className="w-1/2 pb-6 lg:mr-4 lg:w-auto lg:pb-0 lg:last:mr-0">
      {item.widget && isFooter ? (
        <div
          dangerouslySetInnerHTML={{ __html: item.widget.nofollow }}
          onClick={onClick}
        />
      ) : item.widget ? (
        <div
          dangerouslySetInnerHTML={{ __html: item.widget.follow }}
          onClick={onClick}
        />
      ) : item.url ? (
        <Hyperlink
          href={item.url}
          rel={`noreferrer ${isFooter ? 'nofollow' : ''}`}
          onClick={onClick}
          clickTracking={false}
          childrenClassNames="w-1/2 pt-6 lg:w-auto"
        >
          <Img
            className="mx-auto h-14 w-32"
            dataTestId={`${item.id}-item`}
            src={item.img}
            alt={item.alt}
          />
        </Hyperlink>
      ) : (
        <Img
          className="mx-auto h-14 w-32"
          dataTestId={`${item.id}-item`}
          src={item.img}
          alt={item.alt}
        />
      )}
    </div>
  )
}

AwardComponent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    img: PropTypes.string,
    url: PropTypes.string,
    widget: PropTypes.object,
    alt: PropTypes.string,
    isFooter: PropTypes.bool,
    statusCode: PropTypes.number,
  }),
}

const Awards = ({
  className,
  firstGroupTitleMobile,
  desktopTitle,
  secondGroupTitleMobile,
  desktopTitleClassname,
  isFooter,
  statusCode,
}) => {
  const { isMobile } = useDeviceDetect()
  const groups = useMemo(
    () => [
      {
        id: 'e6545c56-7e83-41d7-98e8-6551fa17afd7',
        mobileTitle: firstGroupTitleMobile,
        logos: LogosGroup.mobileGroup.groupOne,
        hasTrustPilotWidget: true,
      },
      {
        id: 'a9dd8630-06fa-4730-91e4-4305c1570260',
        mobileTitle: secondGroupTitleMobile,
        logos: LogosGroup.mobileGroup.groupTwo,
      },
    ],
    [firstGroupTitleMobile, secondGroupTitleMobile]
  )

  return (
    <div
      className={`${className} flex flex-col items-center justify-between`}
      data-testid="awards"
      id="awards"
    >
      {isMobile ? (
        <>
          {groups.map((group) => (
            <React.Fragment key={group.id}>
              <p className="mx-auto mb-9 w-full whitespace-nowrap border-b border-gray-270 pb-1 text-center text-l font-bold leading-6.5 text-blue-700 sm:w-9/12 md:w-1/2">
                {group.mobileTitle}
              </p>
              <div
                className="flex flex-wrap items-center justify-center pb-6"
                data-testid="logos-wrapper"
              >
                {group.hasTrustPilotWidget ? (
                  <div
                    className="w-1/2 pb-6 lg:mr-4 lg:w-auto lg:pb-0"
                    data-testid="trustpilot-widget"
                  >
                    <TrustPilotWidget
                      rel={isFooter ? 'nofollow' : ''}
                      isFooter={isFooter}
                      statusCode={statusCode}
                    />
                  </div>
                ) : null}
                {group.logos.map((item) => {
                  const Component = item.component
                  if (!Component) {
                    return (
                      <AwardComponent
                        key={item.id}
                        item={item}
                        isFooter={isFooter}
                        statusCode={statusCode}
                      />
                    )
                  }

                  return (
                    <Component
                      key={item.id}
                      item={item}
                      isFooter={isFooter}
                      statusCode={statusCode}
                    />
                  )
                })}
              </div>
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <p
            className={`${desktopTitleClassname} mx-auto w-full whitespace-nowrap pb-2 text-center text-l font-bold leading-6.5 text-blue-700`}
          >
            {desktopTitle}
          </p>
          <div className="flex w-full items-center justify-between">
            <div className="lg:mr-4">
              <TrustPilotWidget
                statusCode={statusCode}
                rel={isFooter ? 'nofollow' : ''}
                isFooter={isFooter}
              />
            </div>
            {groups.map((group) =>
              group.logos.map((item) => {
                const Component = item.component
                if (!Component) {
                  return (
                    <AwardComponent
                      key={item.id}
                      item={item}
                      isFooter={isFooter}
                      statusCode={statusCode}
                    />
                  )
                }

                return (
                  <Component
                    key={item.id}
                    item={item}
                    isFooter={isFooter}
                    statusCode={statusCode}
                  />
                )
              })
            )}
          </div>
        </>
      )}
    </div>
  )
}

Awards.defaultProps = {
  className: '',
  desktopTitleClassname: '',
  firstGroupTitleMobile: 'Consumer Approved',
  secondGroupTitleMobile: 'Industry Recognized',
  desktopTitle: 'Consumer approved, industry recognized',
  isFooter: true,
}

Awards.propTypes = {
  className: PropTypes.string,
  firstGroupTitleMobile: PropTypes.string,
  firstGroupTitleDesktop: PropTypes.string,
  desktopTitle: PropTypes.string,
  desktopTitleClassname: PropTypes.string,
  isFooter: PropTypes.bool,
  statusCode: PropTypes.number,
}

export default Awards
