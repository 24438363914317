import HumanApproch from '../../../../public/next-assets/icons/human-approch.svg'
import MovingForward from '../../../../public/next-assets/icons/moving-forward.svg'
import Solutions from '../../../../public/next-assets/icons/solutions.svg'
import Smarter from '../../../../public/next-assets/icons/smarter.svg'
import ChevronThinLeft from '../../../../public/next-assets/icons/chevron-thin-left.svg'
import ChevronThinRight from '../../../../public/next-assets/icons/chevron-thin-right.svg'
import DollarSign from '../../../../public/next-assets/icons/dollar-sign.svg'
import User from '../../../../public/next-assets/icons/user.svg'
import Check from '../../../../public/next-assets/icons/check.svg'
import ArrowRightCircle from '../../../../public/next-assets/icons/arrow-right-circle.svg'
import KeyboardArrowRight from '../../../../public/next-assets/icons/keyboard-arrow-right.svg'
import Instagram from '../../../../public/next-assets/icons/instagram.svg'
import Linkedin from '../../../../public/next-assets/icons/linkedin.svg'
import Twitter from '../../../../public/next-assets/icons/twitter.svg'
import Youtube from '../../../../public/next-assets/icons/youtube.svg'
import Facebook from '../../../../public/next-assets/icons/facebook.svg'
import Home from '../../../../public/next-assets/icons/home.svg'
import ProfileMale from '../../../../public/next-assets/icons/profile-male.svg'
import Document from '../../../../public/next-assets/icons/document.svg'
import MagnifyingGlass from '../../../../public/next-assets/icons/magnifying-glass.svg'
import Checkmark from '../../../../public/next-assets/icons/checkmark-black-bold.svg'
import BankCards from '../../../../public/next-assets/icons/bank-cards.svg'
import Close from '../../../../public/next-assets/icons/close.svg'
import Play from '../../../../public/next-assets/icons/play.svg'
import Email from '../../../../public/next-assets/icons/email.svg'
import Pinterest from '../../../../public/next-assets/icons/pinterest.svg'
import WebPage from '../../../../public/next-assets/icons/link-regular.svg'
import ArrowRight from '../../../../public/next-assets/icons/arrow-right.svg'
import ArrowDown from '../../../../public/next-assets/icons/arrow-down.svg'
import CloseModal from '../../../../public/next-assets/icons/close-modal.svg'
import BulletCheckmark from '../../../../public/next-assets/icons/bullet-checkmark.svg'
import TrustPilotBrand from '../../../../public/next-assets/icons/trustpilot-brand.svg'
import TrustPilotRating from '../../../../public/next-assets/icons/trustpilot-rating.svg'
import Award from '../../../../public/next-assets/icons/award.svg'
import CheckCircle from '../../../../public/next-assets/icons/check-circle.svg'
import FastForward from '../../../../public/next-assets/icons/fast-forward.svg'
import TrendingDown from '../../../../public/next-assets/icons/trending-down.svg'
import DollarSignBlue from '../../../../public/next-assets/icons/dollar-sign-blue.svg'

const svgIconsList = {
  humanApproch: HumanApproch,
  movingForward: MovingForward,
  solutions: Solutions,
  smarter: Smarter,
  chevronThinLeft: ChevronThinLeft,
  chevronThinRight: ChevronThinRight,
  dollarSign: DollarSign,
  user: User,
  check: Check,
  arrowRightCircle: ArrowRightCircle,
  keyboardArrowRight: KeyboardArrowRight,
  instagram: Instagram,
  linkedin: Linkedin,
  twitter: Twitter,
  youtube: Youtube,
  facebook: Facebook,
  home: Home,
  profileMale: ProfileMale,
  document: Document,
  magnifyingGlass: MagnifyingGlass,
  checkmark: Checkmark,
  bankCards: BankCards,
  close: Close,
  play: Play,
  email: Email,
  pinterest: Pinterest,
  webPage: WebPage,
  arrowRight: ArrowRight,
  arrowDown: ArrowDown,
  closeModal: CloseModal,
  bulletCheckmark: BulletCheckmark,
  trustPilotBrand: TrustPilotBrand,
  trustPilotRating: TrustPilotRating,
  award: Award,
  checkCircle: CheckCircle,
  fastForward: FastForward,
  trendingDown: TrendingDown,
  dollarSignBlue: DollarSignBlue,
}

export default svgIconsList
