export const checkWebpSupport = (callback) => {
  const img = new Image()
  img.onload = function () {
    const result = img.width > 0 && img.height > 0
    callback(result)
  }

  img.onerror = function () {
    callback(false)
  }

  img.src = window.location.origin + '/next-assets/pixel-guide.webp'
}
