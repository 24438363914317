export const expectedDomains = [
  'freedomdebtrelief.com',
  'freedomfinancialnetwork.com',
  'freedomplus.com',
  'lendage.com',
  'consolplus.com',
  'www.bills.com',
  'www.achieve.com',
]

export const externalsRegex = new RegExp(
  `^(.*(${expectedDomains.join(
    '|'
  )}).*)|(tel.*)|(mailto.*)|(^/)|(^\\/[a-z0-9]+)$`
)
