import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import SvgIcon from '../SvgIcon'

const SocialMediaIcon = ({ url, type, onClick, dark, id, className }) => (
  <a
    id={id || type}
    href={url}
    data-testid={`${type}-icon`}
    target="_blank"
    rel="nofollow noreferrer"
    className={classNames('inline-block cursor-pointer', className)}
    onClick={onClick}
    aria-label={`${type || id}-link`}
  >
    <SvgIcon
      name={type}
      className={classNames(
        'h-6 w-6',
        { 'fill-blue-600': dark },
        { 'fill-blue-300': !dark }
      )}
    />
  </a>
)

SocialMediaIcon.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
}

SocialMediaIcon.defaultProps = {
  dark: false,
  className: 'mr-2',
}
export default React.memo(SocialMediaIcon)
