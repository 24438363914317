import PropTypes from 'prop-types'
import classNames from 'classnames'
import svgIconsList from './svgIconsList'

const SvgIcon = ({ name, className, ...rest }) => {
  const SvgIconComponent = svgIconsList[name]
  if (!SvgIconComponent) return null

  return (
    <SvgIconComponent
      className={classNames(
        'fill-current text-current inline-block select-none align-text-bottom',
        className
      )}
      {...rest}
    />
  )
}

SvgIcon.defaultPropa = {
  className: 'h-6 w-6',
}

SvgIcon.propTypes = {
  name: PropTypes.oneOf([
    'humanApproch',
    'movingForward',
    'solutions',
    'smarter',
    'chevronThinLeft',
    'chevronThinRight',
    'dollarSign',
    'user',
    'check',
    'arrowRightCircle',
    'keyboardArrowRight',
    'instagram',
    'linkedin',
    'twitter',
    'youtube',
    'facebook',
    'home',
    'profileMale',
    'document',
    'magnifyingGlass',
    'checkmark',
    'bankCards',
    'close',
    'play',
    'email',
    'pinterest',
    'webPage',
    'arrowRight',
    'arrowDown',
    'closeModal',
    'bulletCheckmark',
    'trustPilotBrand',
    'trustPilotRating',
    'award',
    'checkCircle',
    'fastForward',
    'trendingDown',
    'dollarSignBlue',
  ]).isRequired,
  className: PropTypes.string,
}

export default SvgIcon
